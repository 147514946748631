<template>
  <div class="dad">
    <div class="div_hezi" v-if="data.code == 0.2">
      <div class="logo">
        <img src="../../public/images/shenhezhong.png" alt="" />
      </div>
      <div class="text">您的账号正在审核中，请稍等....</div>
    </div>
    <div class="div_hezi" v-else-if="data.code == 0.4">
      <div class="logo">
        <img src="../../public/images/shenheshibai.png" alt="" />
      </div>
      <div class="text">
        账号审核失败
        <div class="yuanyin">失败原因：{{ data.info }}</div>
        <div class="yuanyin">
          <router-link to="/j_regist" v-if="data.type1 == 1">
            <el-button type="info" plain> 重新提交 </el-button>
          </router-link>
          <router-link to="/q_regist" v-else>
            <el-button type="info" plain> 重新提交 </el-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="div_hezi" v-else>
      <div class="logo">
        <img src="../../public/images/buwanshan.png" alt="" />
      </div>
      <div class="text">
        您的信息提交不完善
        <div class="yuanyin">
          <router-link to="/j_regist" v-if="data.type1 == 1">
            <el-button type="info" plain> 去完善 </el-button>
          </router-link>
          <router-link to="/q_regist" v-else>
            <el-button type="info" plain> 去完善 </el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: "",
    };
  },
  mounted() {
    this.data = JSON.parse(window.localStorage.getItem("data"));
  },
};
</script>
<style scoped>
.dad {
  background: url(../../public/images/bssjx.png) no-repeat center;
  height: 100%;
}
.div_hezi {
  width: 550px;
  height: 400px;
  border-radius: 10px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
  background-color: #fff;
  box-shadow: 0px 0px 5px #666;
}
.logo {
  width: 150px;
  position: absolute;
  top: -15%;
  left: 50%;
  transform: translate(-50%);
  height: 150px;
  border-radius: 50%;
  padding: 10px;
  border: 1px solid #eaeaea;
  background-color: #fff;
}
.logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.text {
  margin: 30% 0px;
  /*-webkit-*/
  text-align: center;
  font-size: 20px;
}
.yuanyin {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

@media screen and (max-width: 550px) {
  .div_hezi {
    width: 90%;
    height: 40%;
  }
  .logo {
    width: 100px;
    position: absolute;
    top: -15%;
    left: 50%;
    transform: translate(-50%);
    height: 100px;
    border-radius: 50%;
    padding: 10px;
    border: 1px solid #eaeaea;
    background-color: #fff;
  }
  .text {
    margin: 30% 0px;
    /*-webkit-*/
  }
}
</style>